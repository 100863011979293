<template>
  <el-dialog
    :title="openType == 'edit' ? '修改分销商品' : '参加分销'"
    :visible.sync="changeGoodsVisible"
    :before-close="closeHandle"
    width="50%"
  >
    <el-table
      :data="skuTableData"
      border
      style="width: 100%"
    >
      <el-table-column
        prop="goodsId"
        label="商品id"
        min-width="110"
      />
      <el-table-column
        prop="sellPrice"
        label="商品销售价（元）"
        min-width="110"
      />
      <el-table-column
        prop="sku"
        label="规格"
        min-width="110"
      />
      <!-- <el-table-column
        prop="sku"
        label="sku"
        min-width="110"
      /> -->
      <el-table-column
        label="佣金(元)"
        min-width="110"
      >
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.commission"
            size="mini"
          ></el-input>
        </template>
      </el-table-column>
    </el-table>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        type="primary"
        @click="onSure"
      >{{
        openType == "edit" ? "确认修改" : "确认参加"
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'AddGoodsDialog',
  props: {
    changeGoodsVisible: Boolean,
    goodsRow: Object,
    openType: String
    // promId: String

  },
  data() {
    return {
      skuTableData: [] //
    };
  },
  mounted() {
    this.getSkuTable();
  },
  methods: {
    closeHandle() {
      this.$emit('update:changeGoodsVisible', false);
    },
    // 获取多规格列表
    getSkuTable() {
      let _this = this;
      let params = {
        goodsid: _this.goodsRow.id
      }
      let url = _this.openType == 'add' ? _this.$api.DistributeController.getGoodsSku : _this.$api.DistributeController.getSelectGoodsSku
      _this
        .$axios(url, {
          params
        })
        .then(function(res) {
          if (res.code === 0) {
            _this.skuTableData = res.data;
          } else {
            _this.skuTableData = [];
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    // 确认&修改报名
    onSure() {
      let url =
        this.openType === 'add'
          ? this.$api.DistributeController.addGoodsSku
          : this.$api.DistributeController.updateGoodsSku;
      let params = {
        ...this.goodsRow,
        skuDtoList: this.skuTableData
      }

      this.$axios.post(url, params).then((res) => {
        if (res.code === 0) {
          this.closeHandle();
          this.$message.success(
            `${this.openType === 'add' ? '商品分销成功~' : '编辑成功~'}`
          );
          if (this.openType === 'add') {
            this.$router.push({
              path: '/nb/distribution/goodsSetting',
              query: {
                // promId: this.$route.query.id
              }
            })
          } else {
            this.$emit('onupdatetable');
          }
        }
      });
    }
  }
};
</script>
