<template>
  <el-dialog v-if="showOff" title="关联自提门店" :visible.sync="showOff" width="600px">
    <el-checkbox-group v-model="checkList">
      <el-checkbox class="checkbox" v-for="item in list" :key="item.templateId" :label="item.templateId">
        <div>门店名称：{{item.selfName}}</div>
        <div>联系方式：{{item.phone}}</div>
        <div>门店地址：{{item.address}}</div>
      </el-checkbox>
    </el-checkbox-group>
    <el-button type="primary" size="mini" @click="subClick">确定</el-button>
  </el-dialog>
</template>

<script>
export default{
  data() {
    return {
      showOff: false,
      checkList: [],
      list: []
    }
  },
  methods: {
    show(selected) {
      this.list = [];
      this.checkList = selected.map(item => item.templateId);
      this.getData();
      this.showOff = true;
    },
    subClick() {
      if(!this.checkList.length) {
        this.$message({type: 'error', message: '请选择自提点'});
        return;
      }

      const sends = this.list.filter(item => this.checkList.includes(item.templateId));

      this.showOff = false;
      this.$emit('check', sends);
    },
    async getData() {
      const params = {templateType: 3, storeId: this.$route.query.storeId};
      const {code, data, msg} = await this.$axios.get(this.$api.trade_template_mail.list, {params});
      
      if(code !== 0) {
        this.$message({type: 'error', message: msg});
        return;
      }

      this.list = data || [];

      this.list.forEach(item => {
        item.templateId = item.id;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox{
  display: flex; align-items: center; margin-bottom: 20px;
}
</style>