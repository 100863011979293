var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "el-form",
        {
          ref: "freightForm",
          staticClass: "demo-ruleForm",
          attrs: { "label-width": "110px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "普通快递：" } },
            [
              _vm.people === "gys"
                ? _c(
                    "el-select",
                    {
                      staticClass: "width360",
                      attrs: {
                        disabled: _vm.disabled,
                        placeholder: "请选择快递模板",
                        size: "mini",
                        "show-word-limit": "",
                        "value-key": "id",
                        clearable: "",
                      },
                      on: { "visible-change": _vm.visibleChangeCommon },
                      model: {
                        value: _vm.ruleForm.commonFreight,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "commonFreight", $$v)
                        },
                        expression: "ruleForm.commonFreight",
                      },
                    },
                    _vm._l(_vm.commonList, function (item, i) {
                      return _c("el-option", {
                        key: i,
                        attrs: { label: item.name, value: item },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm.people === "gys"
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "30px" },
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.addSelfFreight(1)
                        },
                      },
                    },
                    [_vm._v(" +新增普通快递 ")]
                  )
                : _vm._e(),
              _vm.people === "admin"
                ? _c(
                    "div",
                    [
                      _vm.ruleForm.commonFreight &&
                      _vm.ruleForm.commonFreight.name
                        ? _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.toFreightList(1)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.ruleForm.commonFreight.name) +
                                  " "
                              ),
                            ]
                          )
                        : _c("span", [_vm._v("不支持")]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.$route.query.goodsType != 7
            ? _c(
                "el-form-item",
                { attrs: { label: "同城配送：" } },
                [
                  _vm.people === "gys"
                    ? _c(
                        "el-select",
                        {
                          staticClass: "width360",
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择快递模板",
                            size: "mini",
                            "show-word-limit": "",
                            "value-key": "id",
                            clearable: "",
                          },
                          on: { "visible-change": _vm.visibleChangeCity },
                          model: {
                            value: _vm.ruleForm.cityFreight,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "cityFreight", $$v)
                            },
                            expression: "ruleForm.cityFreight",
                          },
                        },
                        _vm._l(_vm.cityList, function (item, i) {
                          return _c("el-option", {
                            key: i,
                            attrs: { label: item.name, value: item },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.people === "gys"
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "30px" },
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.addSelfFreight(2)
                            },
                          },
                        },
                        [_vm._v(" +新增同城配送 ")]
                      )
                    : _vm._e(),
                  _vm.people === "admin"
                    ? _c(
                        "div",
                        [
                          _vm.ruleForm.cityFreight &&
                          _vm.ruleForm.cityFreight.name
                            ? _c(
                                "el-link",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toFreightList(2)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ruleForm.cityFreight.name) +
                                      " "
                                  ),
                                ]
                              )
                            : _c("span", [_vm._v("不支持")]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.$route.query.goodsType != 7
            ? _c("el-form-item", { attrs: { label: "自提：" } }, [
                _vm.people === "gys"
                  ? _c(
                      "div",
                      { staticClass: "tags-box" },
                      [
                        !_vm.ruleForm.ztds.length
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  height: "32px",
                                  "line-height": "32px",
                                  "margin-bottom": "10px",
                                },
                              },
                              [_vm._v("未选择自提点")]
                            )
                          : _vm._e(),
                        _vm._l(_vm.ruleForm.ztds, function (tag) {
                          return _c(
                            "el-tag",
                            {
                              key: tag.templateId,
                              attrs: {
                                closable: "",
                                type: "primary",
                                "disable-transitions": true,
                              },
                              on: {
                                close: function ($event) {
                                  return _vm.closeTag(tag)
                                },
                              },
                            },
                            [_vm._v(_vm._s(tag.selfName))]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _vm.people === "gys"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: { click: _vm.selectztd },
                          },
                          [_vm._v(" 选择自提点 ")]
                        )
                      : _vm._e(),
                    _vm.people === "gys"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.addSelfFreight(3)
                              },
                            },
                          },
                          [_vm._v(" +新增自提地址 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.people === "admin"
                  ? _c("div", [
                      _vm.ruleForm.selfFreight &&
                      _vm.ruleForm.selfFreight.templateId == "0"
                        ? _c("span", { staticStyle: { color: "#1890ff" } }, [
                            _vm._v("支持"),
                          ])
                        : _c("span", [_vm._v("不支持")]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _c("el-form-item", { attrs: { label: "提示：" } }, [
            _c("div", [
              _vm._v(
                " 设置运费之前需要配置相应模式的运费模板，如果没有配置，点击进行新增。 "
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm.ruleForm.commonFreight || _vm.ruleForm.cityFreight
        ? _c("FreightTimeInfo", {
            attrs: { disabled: _vm.disabled, "freight-time": _vm.freightTime },
          })
        : _vm._e(),
      _c("Zitidian", { ref: "ZitidianRef", on: { check: _vm.checkAddress } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "add_goods_title" }, [
      _vm._v(" 设置运费"),
      _c("span", [_vm._v("(以下3中配送方式需任选一种或多种)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }