var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "page-content",
        [
          _c(
            "search-panel",
            {
              attrs: { "show-btn": "", flex: "" },
              on: {
                getList: function ($event) {
                  return _vm.searchList()
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c("p", [_vm._v("商品ID")]),
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "请输入商品ID",
                      size: "mini",
                    },
                    model: {
                      value: _vm.goodsId,
                      callback: function ($$v) {
                        _vm.goodsId = $$v
                      },
                      expression: "goodsId",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "page-content",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { data: _vm.list },
            },
            [
              _c("el-table-column", {
                attrs: { label: "商品ID", prop: "goodsId" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("span", [_vm._v(_vm._s(row.goodsId || "-"))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "商品名称", prop: "goodsName" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [_vm._v(_vm._s(row.goodsName || "-"))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", prop: "logTitle" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作账号", prop: "operator" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作平台", prop: "platform" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作时间", prop: "supplierId" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(" " + _vm._s(_vm.getTime(row.updateTime)) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "信息" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm.detailIds.includes(row.logType)
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getDetail(row.id)
                                  },
                                },
                              },
                              [_vm._v(" 更新信息 ")]
                            )
                          : _c(
                              "span",
                              { staticStyle: { "padding-left": "30px" } },
                              [_vm._v("-")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.pageSize,
              layout: "total, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: { "current-change": _vm.handleCurrentChange },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "更新信息",
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.detail.validStartTime
            ? _c("div", [
                _vm._v(
                  " 分销时间：" +
                    _vm._s(_vm.getTime(_vm.detail.validStartTime)) +
                    " 至 " +
                    _vm._s(_vm.getTime(_vm.detail.validEndTime)) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.detail.logGoodsSkuDtoList
            ? _c(
                "div",
                [
                  _c("div", { staticStyle: { padding: "20px 0" } }, [
                    _vm._v(" 商品信息 "),
                  ]),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.detail.logGoodsSkuDtoList,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "商品ID", prop: "goodsId" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "商品销售价（元）", prop: "sellPrice" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "sku", label: "规格" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "佣金（元）", prop: "commission" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }