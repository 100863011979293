// 新船-订单模块
const base = APPLICATION_ENV.ORDER_API;
// const base = 'http://10.10.10.46:8091/' // 活动模块
// const base = 'http://10.10.10.158:8086/' //优惠券模块
// const base = 'http://10.10.10.61:8086/' //拼团模块
// const base = 'http://10.10.10.158:8086/' //订单

const orderApis = {
  order: {
    orderList: `${base}back-supplier/order/list`,
    orderDetail: `${base}back-supplier/order/detail`,
    remark: `${base}back-supplier/order/remark`,
    export: `${base}back-supplier/order/detail/export`,
    refuseOrder: `${base}/back-supplier/order/refuseOrder`, // 民宿拒绝接单
    receiveOrder: `${base}/back-supplier/order/receiveOrder`, // 民宿接单
    jdList: `${base}back-supplier/withdraw/jd-settlement/list`, // 京东运费结算单列表（订单维度）
    jdDownLoad: `${base}back-supplier/withdraw/jd-settlement/download`, // 京东运费结算数据下载,
    verify: `${base}app-supplier/verify-order/all`, // 订单核销
    receiveAnalysis: `${base}back-supplier/modify_receive/receive_analyse`, // 收货信息智能识别
    modifyReceive: `${base}back-supplier/modify_receive/modify_receive`, // 修改地址
    modifyReceivePage: `${base}back-supplier/modify_receive/modify_receive_page`, // 申请修改地址分页
    modifyReceiveRefuseList: `${base}back-supplier/modify_receive/modify_receive_refuse_list`, // 拒绝原因列表
    modifyReceiveReview: `${base}back-supplier/modify_receive/modify_receive_review`, // 审核修改地址
  },
  refund: {
    refundList: `${base}back-supplier/refund/list/refund`,
    refund_export: `${base}back-supplier/refund/list/refund_export`, // 售后列表导出
    refundDetail: `${base}back-supplier/refund/get/refund`,
    refundGoods: `${base}back-supplier/refund/get/refundgoods`,
    refundConsult: `${base}back-supplier/refund/get/refundconsult`,
    refundAudit: `${base}back-supplier/refund/audit`,
    refundTrack: `${base}back-supplier/order/query/track`,
    getExchangeOrder: `${base}back-supplier/refund/get/exchange-order`, // 换购订单列表

    addressList: `${base}back-supplier/refund-address/list`, // 退货地址
    refundAddressList: `${base}back-supplier/refund/refundAddress/list`, // 售后退货地址列表
    agreeDeliver: `${base}back-supplier/refund/agree-deliver`, // 商家同意退货
    refuseDeliver: `${base}back-supplier/refund/refuse-deliver`, // 商家拒绝退货
    refuseReason: `${base}back-supplier/refund/get/refuse-reason`, // 拒绝理由枚举
    refuseRefund: `${base}back-supplier/refund/refuse-refund`, // 商家拒绝退款
    agreeRefund: `${base}back-supplier/refund/agree-refund`, // 商家同意退款
    interveneAddEvidence: `${base}back-supplier/refund-intervene/add/evidence`, // 上传举证
    interveneDetailList: `${base}back-supplier/refund-intervene/detail-list`, // 介入举证列表详情
    refundreason: `${base}back-supplier/refund/get/refund-reason`, // 售后理由枚举
    edit: `${base}back-supplier/refund/edit`, // 平台修改售后
    replenishType: `${base}back-supplier/refund-intervene/replenishType`, // 补充举证类型
    replenish: 'back-supplier/refund-intervene/replenish', // 补充举证
    openRefundShow: 'back-supplier//refund/open-refund-show', // 开启售后
  },
  delivery: {
    deliveryTemplate: `${base}back-supplier/order/download/template`,
    deliveryUpload: `${base}back-supplier/order/import/delivery`,
    deliveryList: `${base}back-supplier/order/delivery/records`,
    manualDelivery: `${base}back-supplier/order/manual/delivery`,
    editDelivery: `${base}back-supplier/order/delivery/records/edit`,
    splitDelivery: `${base}/back-supplier/order/split/delivery`, // 拆单发货
    record: `${base}back-supplier/deliver/record/page`, // 已发货-分页列表
    batch_edit: `${base}back-supplier/deliver/record/batch_edit`, // 已发货-批量修改运单
    detail: `${base}back-supplier/deliver/record/detail`, // 已发货-物流详情
  },

  // 活动模块
  tradeProm: {
    activity_list: `${base}back-supplier/tradeProm/list/prom`, // 活动列表
    activity_detail: `${base}back-supplier/tradeProm/get/prom`, // 活动详情
    get_reason: `${base}back-supplier/tradeProm/get/promgoodsdto`, // 查看原因
    goods_requirement: `${base}back-supplier/tradeProm/get/promgoodsrequirement`, // 商品资质获取
    store_requirement: `${base}back-supplier/tradeProm/get/promstorerequirement`, // 商家资质获取
    activity_goods_list: `${base}back-supplier/tradeProm/list/goods`, // 活动商品列表
    promgoodsskulist: `${base}back-supplier/tradeProm/promgoodsskulist`, // 活动商品多规格列表
    goodsskulist: `${base}back-supplier/tradeProm/goodsskulist`, // 参加活动
    sure_apply: `${base}back-supplier/tradeProm/add`, // 确认报名
    re_sure_apply: `${base}back-supplier/tradeProm/readd`, // 重新报名
    cancel_apply: `${base}back-supplier/tradeProm/cancel`, // 取消报名
    delete_apply: `${base}back-supplier/tradeProm/delete`, // 删除
    change_apply: `${base}back-supplier/tradeProm/update/promgoodssku`, // 修改报名信息
    add_stock: `${base}back-supplier/tradeProm/update/promgoodssku/stock`, // 增加库存
    add_hotel: `${base}back-supplier/tradeProm/add/hotel`, // 民宿商品报名
    add_hotel_sku: `${base}back-supplier/tradeProm/get/hotel-sku`, // 民宿商品规格
    re_add_hotel: `${base}back-supplier/tradeProm/re-add/hotel`, // 民宿商品重新报名
  },

  // 优惠券模块
  coupon: {
    over_view_result: `${base}back-supplier/CouponController/couponResultSupplierBG`, // 获取概览数据
    sub_type_num: `${base}back-supplier/CouponController/everyTypeOfCouponCountSupplierBG`, // 每种类型优惠券数量
    template_add: `${base}back-supplier/CouponController/AddCoupon`, // 创建优惠券
    goods_add_list: `${base}back-supplier/CouponController/selecCouponGoodsInfo`, // 添加优惠券商品
    live_add_list: `${base}back-supplier/CouponController/getSuppliersLive`, // 获取商家开启的直播活动
    template_edit: `${base}back-supplier/CouponController/editorCoupon`, // 编辑优惠券
    template_detail: `${base}back-supplier/CouponController/selecCouponDetailsDto`, // 优惠券详情
    template_goods_list: `${base}back-supplier/CouponController/selectGoodsByCouponAssociatedIds`, // 通过优惠券关联id获取商品列表详情
    template_live_list: `${base}back-supplier/CouponController/selectLiveByCouponAssociatedIds`, // 通过优惠券关联id获取直播列表详情

    sub_coupon_list: `${base}back-supplier/CouponController/SelectCouponTypeByPlatfrom`, // 获取优惠券下拉框类型
    template_list: `${base}back-supplier/CouponController/SearchStoreCoupon`, // 优惠券列表
    search_return_coupon: `${base}back-supplier/CouponController/searchReturnCoupon`, // 优惠券列表(可查全部券包和优惠券id)
    add_coupon_count: `${base}back-supplier/CouponController/addCouponCount`, // 增加优惠券发放数量
    copy_link: `${base}back-supplier/CouponController/copyLink`, // 复制链接
    end_coupon: `${base}back-supplier/CouponController/endCoupon`, // 结束优惠券

    get_all_coupon_data: `${base}back-supplier/CouponController/couponStoreStaticsSuppliers`, // 获取优惠券数据效果

    template_import_store: `${base}back-supplier/CouponController/importCouponStore`, // 导入优惠券店铺信息
    template_import_goods: `${base}back-supplier/CouponController/importCouponGoods`, // 导入优惠券商品信息
    template_export: `${base}back-supplier/CouponController/downloadDeliveryTemplate`, // 下载导入模板

    template_group_detail: `${base}back-supplier/CouponController/selectActivityByCouponAssociatedIds`, // 通过优惠券关联id获取活动分组详情
    template_store_detail: `${base}back-supplier/CouponController/selectSuppliersByCouponAssociatedIds`, // 通过优惠券关联id获取店铺详情

    template_site_detail: `${base}back-supplier/CouponController/selectSiteByCouponAssociatedIds`, // 通过优惠券关联id获取场馆详情

    package_add: `${base}back-supplier/CouponController/AddCouponPackage`, // 优惠券包新建
    package_search_coupon: `${base}back-supplier/CouponController/SearchBGCoupon`, // 优惠券包添加关联优惠券
    package_edit: `${base}back-supplier/CouponController/updataCouponPackage`, // 优惠券包编辑
    package_detail: `${base}back-supplier/CouponController/selectCouponPackageDetailById`, // 优惠券包详情
    package_list: `${base}back-supplier/CouponController/SearchCouponPackage`, // 优惠券包列表

    grant_add: `${base}back-supplier/CouponController/distributedCouponPackage`, // 指定用户发放券包
    grant_list: `${base}back-supplier/CouponController/selectCouponPackageGrantUser`, // 发放记录

    operate_coupon: `${base}app-supplier/CouponController/operate_coupon`, // 优惠券上下架操作

    search_coupon_package: `${base}back-supplier/CouponController/search-coupon-package`, // 平台优惠券列表
    download: `${base}back-supplier/CouponController/redeemCode/download`, // 下载兑换码
  },

  liveShare: {
    share_msg: `${base}share/share`, // 获取分享信息
  },

  settlement: {
    list: `${base}back-supplier/withdraw/list/settlement`, // 结算单列表
    add: `${base}back-supplier/withdraw/add/withdraw`, // 提现申请
    download: `${base}back-supplier/withdraw/download/settlement`, // 下载结算数据
    downloadGoods: `${base}back-supplier/withdraw/download/settlement-goods`, // 商家端结算数据下载（商品维度）
    account: `${base}back-supplier/withdraw/get/account`, // 我的账户
    accountlist: `${base}back-supplier/withdraw/get/accountlist`, // 收支明细
    withdrawList: `${base}back-supplier/withdraw/list/withdraw`, // 提现列表
    get: `${base}back-supplier/withdraw/get/billing`, // 收款信息
    update: `${base}back-supplier/withdraw/update/billing`, // 更新收款信息
    settleDetail: `${base}/app-supplier/withdraw/settle/detail`, // 农信结算单
    membership_settlement: `${base}/back-supplier/withdraw/membership-settlement/page`, // 会员卡充值结算列表
    membership_checkStore: `${base}/back-supplier/membership-card/checkStore`, // 验证是否为会员卡店铺
    // 工会福利相关
    union_list: `${base}/back-supplier/trade-settlement-union/type/list`, // 获取工会列表
    union_relation_list: `${base}/back-supplier/trade-settlement-union/type/relation/list`, // 获取工会下福利券列表
    trade_settlement_union_list: `${base}/back-supplier/trade-settlement-union/list`, // 工会结算单列表
    trade_settlement_union_settled: `${base}/back-supplier/trade-settlement-union/un-settled-amount`, // 工会结算单待结算金额
    trade_settlement_union_download: `${base}/back-supplier/trade-settlement-union/download`, // 导出工会结算单列表
  },

  // 拼团
  groupBook: {
    book_list: `${base}backsupplier/group_goods/list`, // 拼团列表
    book_add: `${base}backsupplier/group_goods/add`, // 商品拼团添加
    book_goods_search: `${base}backsupplier/group_goods/goods_search`, // 拼团商品搜索
    book_detail: `${base}backsupplier/group_goods/detail`, // 拼团详情
    book_edit: `${base}backsupplier/group_goods/edit`, // 拼团编辑
    book_del: `${base}backsupplier/group_goods/del`, // 删除
    book_stop: `${base}backsupplier/group_goods/stop`, // 删除
  },
  DistributeController: {
    commissionRecord: `${base}back-supplier/DistributeController/commissionRecord`, // 佣金明细接口
    selecDistributeConf: `${base}back-supplier/DistributeController/new_selecDistributeConf`, // 获取商家分销设置
    selectGoodsForDistributePage: `${base}back-supplier/DistributeController/selectGoodsForDistributePage`, // 获取参与分销的商品
    updateDistributeConf: `${base}back-supplier/DistributeController/new_updateDistributeConf`, // 更新商家分销设置
    getCommissionDetail: `${base}back-supplier/DistributeController/getCommissionDetail`, // 用户提现明细商品列表

    getAllGoods: `${base}back-supplier/DistributeController/new_selectSupplierDistributeGoods`, // 获取可以参与分销的所有商品
    getGoodsSku: `${base}back-supplier/DistributeController/new_selectGoodsSkuForDistribute`, // 获取可以参与分销的商品sku列表
    addGoodsSku: `${base}back-supplier/DistributeController/new_addGoodsSkuForDistribute`, // 添加商品sku为分销商品
    getSelectGoods: `${base}back-supplier/DistributeController/new_selectDistributeGoods`, // 获取分销商品
    delSelectGoods: `${base}back-supplier/DistributeController/new_deleteDistributeGoods`, // 删除分销商品
    getSelectGoodsSku: `${base}back-supplier/DistributeController/new_selectDistributeGoodsSku`, // 获取已添加的分销商品sku
    updateGoodsSku: `${base}back-supplier/DistributeController/new_updateDistributeGoodsSku`, // 更新分销商品sku信息
    updateGoodsTime: `${base}back-supplier/DistributeController/new_updateDistributeGoodsTime`, // 更新分销商品有效时间

    logList: `${base}/back-supplier/trade-distribute-log/supplier_distribute_log`, // 分销日志列表
    logDetail: `${base}/back-supplier/trade-distribute-log/supplier_distribute_log_detail`, // 查看分销详情
    batchAddDistribute: `${base}back-supplier/DistributeController/batch-add-distribute-goods`, // 批量添加分销商品
    batchEditDistribute: `${base}back-supplier/DistributeController/batch-edit-distribute-goods`, // 批量添加分销商品
  },

  // 加价换购
  exchangeOrder: {
    list: `${base}/back-supplier/trade-exchange/list`, // 列表
    add: `${base}/back-supplier/trade-exchange/add`, // 新增
    updateStatus: `${base}/back-supplier/trade-exchange/update/status`, // 更新上下架状态
    skuGoodList: `${base}/back-supplier/trade-exchange/goods-sku`, // 商品列表
    detail: `${base}/back-supplier/trade-exchange/detail`, // 详情
    edit: `${base}/back-supplier/trade-exchange/update`, // 编辑
  },
  // 社区团购
  cmty_pur_store_goods: {
    getStoreGoodsList: `${base}/consumer/cmty-pur-store-goods/getStoreGoodsList`, // 获取商家-商品列表
    addCmtyPurStoreGoods: `${base}/consumer/cmty-pur-store-goods/addCmtyPurStoreGoods`, // 添加商家团购商品
    delete: `${base}/consumer/cmty-pur-store-goods/delete`, // 退出商家团购
    editCmtyPurStoreGoods: `${base}/consumer/cmty-pur-store-goods/editCmtyPurStoreGoods`, // 更新商家团购商品设置
    getCmtyPurStoreGoodsList: `${base}/consumer/cmty-pur-store-goods/getCmtyPurStoreGoodsList`, // 获取商家-团购商品列表
    getQrCode: `${base}/consumer/cmty-pur-store-goods/getQrCode`, // 生成二维码get
  },
  share: {
    share: `${base}/back-supplier/share/share`, // 分享接口
  },
  // 发货中心
  deliver_center: {
    download_template: `${base}/back-supplier/deliver/download/template`, // 下载导入模板
    download_instructions: `${base}/back-supplier/deliver/download/instructions`, // 下载说明模板
    download_import: `${base}back-supplier/deliver/import`, // 导入发货
    download_import_page: `${base}/back-supplier/deliver/import/page`, // 导入发货-预览分页
    download_import_cancel: `${base}/back-supplier/deliver/import/cancel`, // 导入发货-取消
    download_import_delete: `${base}/back-supplier/deliver/import/delete`, // 导入发货-删除
    download_import_submit: `${base}/back-supplier/deliver/import/submit`, // 导入发货-确认导入
    download_import_detail: `${base}/back-supplier/deliver/import/detail`, // 导入发货-订单的运单详情（关联商品）
    download_import_edit: `${base}/back-supplier/deliver/import/edit`, // 导入发货-关联商品编辑
    download_goodsList: `${base}/back-supplier/deliver/goodsList`, // 某订单商品发货情况列表
    download_deliverySubmit: `${base}/back-supplier/deliver/deliverySubmit`, // 订单列表页面单笔订单点击发货
    download_logisticByNo: `${base}/back-supplier/deliver/logisticByNo`, // 根据单号获取快递公司
  },
  appSupplierStore: {
    storeOrderData: `${base}/app-supplier/store/storeOrderData`, // 获取当前用户下的用户订单
  },
  // 地址库管理
  address: {
    list: `${base}/back-supplier/refund-address/list`, // 地址库列表
    add: `${base}/back-supplier/refund-address/add`, // 地址库新增
    edit: `${base}/back-supplier/refund-address/update`, // 地址库编辑
    delete: `${base}/back-supplier/refund-address/delete`, // 地址库详情
  },
  // 通兑卡-预约列表
  userExchangeCard: {
    appoint_list: `${base}/back-supplier/userExchangeCard/appoint/list`, // 预约列表
    open_cancel: `${base}/back-supplier/userExchangeCard/open/cancel`, // 打开取消入口
  },
};

export default orderApis;
