var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showOff
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: "关联自提门店",
            visible: _vm.showOff,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showOff = $event
            },
          },
        },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.checkList,
                callback: function ($$v) {
                  _vm.checkList = $$v
                },
                expression: "checkList",
              },
            },
            _vm._l(_vm.list, function (item) {
              return _c(
                "el-checkbox",
                {
                  key: item.templateId,
                  staticClass: "checkbox",
                  attrs: { label: item.templateId },
                },
                [
                  _c("div", [_vm._v("门店名称：" + _vm._s(item.selfName))]),
                  _c("div", [_vm._v("联系方式：" + _vm._s(item.phone))]),
                  _c("div", [_vm._v("门店地址：" + _vm._s(item.address))]),
                ]
              )
            }),
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.subClick },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }