<template>
  <div>
    <page-content>
      <search-panel
        show-btn
        flex
        @getList="searchList()"
      >
        <div>
          <p>商品ID</p>
          <el-input
            v-model="goodsId"
            clearable
            placeholder="请输入商品ID"
            size="mini"
          />
        </div>
      </search-panel>
    </page-content>
    <page-content>
      <el-table
        v-loading="loading"
        :data="list"
      >
        <el-table-column
          label="商品ID"
          prop="goodsId"
        >
          <template slot-scope="{row}">
            <span>{{ row.goodsId|| '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="商品名称"
          prop="goodsName"
        >
          <template slot-scope="{row}">
            <span>{{ row.goodsName|| '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          prop="logTitle"
        />
        <el-table-column
          label="操作账号"
          prop="operator"
        />
        <el-table-column
          label="操作平台"
          prop="platform"
        />
        <el-table-column
          label="操作时间"
          prop="supplierId"
        >
          <template slot-scope="{row}">
            {{ getTime(row.updateTime) }}
          </template>
        </el-table-column>
        <el-table-column
          label="信息"
        >
          <template slot-scope="{row}">
            <el-button
              v-if="detailIds.includes(row.logType)"
              size="mini"
              type="primary"
              @click="getDetail(row.id)"
            >
              更新信息
            </el-button>
            <span
              v-else
              style="padding-left: 30px"
            >-</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </page-content>
    <el-dialog
      title="更新信息"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <div v-if="detail.validStartTime">
        分销时间：{{ getTime(detail.validStartTime) }} 至 {{ getTime(detail.validEndTime) }}
      </div>
      <div v-if="detail.logGoodsSkuDtoList">
        <div style="padding: 20px 0">
          商品信息
        </div>
        <el-table
          :data="detail.logGoodsSkuDtoList"
          border
        >
          <el-table-column
            label="商品ID"
            prop="goodsId"
          />
          <el-table-column
            label="商品销售价（元）"
            prop="sellPrice"
          />
          <el-table-column
            prop="sku"
            label="规格"
          />
          <el-table-column
            label="佣金（元）"
            prop="commission"
          />
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { set } from 'lodash'
import Moment from 'moment'
export default {
  data() {
    return {
      loading: false,
      list: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      goodsId: '',
      dialogVisible: false,
      detail: {},
      detailIds: [3, 4, 5]
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      const params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize
      }
      this.goodsId.trim() && set(params, 'goodsid', this.goodsId)
      this.$axios.get(this.$api.DistributeController.logList, {
        params
      }).then(res => {
        this.loading = false
        if (res.code === 0) {
          this.list = res.data.records
          this.total = res.data.total
        }
      })
    },
    searchList() {
      this.currentPage = 1
      this.getList()
    },
    getDetail(id) {
      this.$axios.get(this.$api.DistributeController.logDetail, {
        params: {
          goodsId: id
        }
      }).then(res => {
        console.log(res)
        this.dialogVisible = true
        this.detail = res.data
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getList()
    },
    handleClose() {
      this.dialogVisible = false
    },
    getTime(time) {
      return Moment(Number(time)).format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
