<template>
  <div class="component">
    <h1
      ref="scrollIntoView"
      class="title"
    >
      特殊认证
    </h1>
    <div class="goods_authentication_parent">
      <el-card class="wd-display-inblock">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          label-width="100px"
        >
          <div
            v-for="(item, index) in ruleForm.authList"
            :key="item.id"
          >
            <div
              class="line_box wd-flex"
            >
              <div class="line_check wd-flex wd-flex-align-center">
                <el-checkbox
                  v-model="item.isShowAuth"
                  :disabled="disabled"
                />
                <img
                  class="logo"
                  :src="item.image"
                  alt=""
                />
                {{ item.title }}
              </div>

              <div
                v-if="item.isShowAuth"
                class="line_box_right wd-flex"
              >
                <el-form-item
                  label="认证名称"
                  :prop="`authList[${index}].name`"
                  :rules="[{ required: true, message: '请输入认证名称', trigger: 'blur' }]"
                >
                  <el-input
                    v-model="item.name"
                    placeholder="请输入认证名称"
                    size="mini"
                    maxlength="15"
                    show-word-limit
                    :readonly="disabled"
                  />
                </el-form-item>
                <el-form-item
                  label="认证编号"
                  :prop="`authList[${index}].code`"
                  :rules="[{ required: true, message: '请输入认证编号', trigger: 'blur' }]"
                >
                  <el-input
                    v-model="item.code"
                    placeholder="请输入认证编号"
                    size="mini"
                    maxlength="20"
                    show-word-limit
                    :readonly="disabled"
                  />
                </el-form-item>
                <el-form-item
                  label="有效期"
                  :prop="`authList[${index}].daysList`"
                  :rules="[{ required: true, type: 'array', message: '请选择有效期', trigger: 'change' }]"
                >
                  <el-date-picker
                    v-model="item.daysList"
                    style="width: 350px"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="timestamp"
                    size="mini"
                    :readonly="disabled"
                  />
                </el-form-item>
                <el-form-item
                  label="认证证书"
                  :prop="`authList[${index}].imageList`"
                  :rules="[{ required: true,type: 'array', message: '请上传认证证书', trigger: 'change' }]"
                >
                  <UpImage
                    :disabled="disabled"
                    :image-list.sync="item.imageList"
                    :num="5"
                  />
                  <div class="gray">
                    最多上传5张证书图片
                  </div>
                </el-form-item>
              </div>
            </div>
          </div>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import UpImage from '@/components/common/upImage/index';
// import { projectConfigDetail } from '@/utils/projectConfig';

// const { goods: { authentication } } = projectConfigDetail;
const authentication = [
  {
    type: 1,
    title: '绿食认证',
    image: 'https://wsnbh-img.hzanchu.com/h5/nbh_admin/img/20230922173855.png',
  },
  {
    type: 5,
    title: '地理标识认证',
    image: 'https://wsnbh-img.hzanchu.com/h5/nbh_admin/img/20230922173922.png',
  },
];

export default {
  name: '',
  components: {
    UpImage,
  },
  props: {
    disabled: Boolean,
    goodsCertificates: Array,
  },
  data() {
    return {
      query: {},
      ruleForm: {
        // authList: authentication.map((item) => {
        //   return {
        //     ...item,
        //     isShowAuth: false,
        //     name: '',
        //     code: '',
        //     daysList: [], // 选择时间
        //     imageList: [], // 图片列表
        //   };
        // }),
        authList: []
      },
    };
  },
  computed: {},
  watch: {
    goodsCertificates: {
      handler(arr) {
        if (!arr) return;
        this.ruleForm.authList.forEach((item) => {
          let find = arr.find((fin) => fin.certificateId === item.id);
          if (!find) return;
          const {
            certificateName, certificateCode, validStartTime, validEndTime, certificate,
          } = find;
          item.isShowAuth = true;
          item.name = certificateName;
          item.code = certificateCode;
          item.daysList = [new Date(validStartTime).getTime(), new Date(validEndTime).getTime()];
          item.imageList = certificate ? certificate.split(',') : [];
        });
      },
      deep: false,
      immediate: false,
    },
  },
  created() { },
  mounted() {
    this.query = this.$route.query;
    this.getData();
  },
  methods: {
    getForm() {
      return new Promise((resolve) => {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            let params = [];
            for (let item of this.ruleForm.authList) {
              const {
                isShowAuth, code, name, imageList, daysList, id,
              } = item;
              if (isShowAuth) {
                const obj = {
                  certificateCode: code,
                  certificateName: name,
                  certificate: imageList.join(','),
                  validStartTime: daysList[0],
                  validEndTime: daysList[1],
                  certificateId: id,
                  goodsId: this.query.goodsId || undefined,
                };
                params.push(obj);
              }
            }
            resolve(params.length > 0 ? params : null);
          } else {
            this.$message.warning('请完善特殊认证');
            this.$refs.scrollIntoView.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
            return false;
          }
        });
      });
    },
    async getData() {
      const {code, data, msg} = await this.$axios.get(this.$api.goodsmanage.trade_goods_baseList);
      if (code !== 0) {
        this.$message({message: msg, type: 'error'});
        return;
      }

      if (!data) return;

      this.ruleForm.authList = data.map(item => ({
        ...item,
        title: item.name,
        isShowAuth: false,
        name: '',
        code: '',
        image: item.logoUrl,
        daysList: [], // 选择时间
        imageList: [], // 图片列表
      }));
    }
  },
};
</script>

<style scoped lang="scss">
.goods_authentication_parent {
  .wd-display-inblock {
    min-width: 400px;
  }
  .line_check {
    min-width: 170px;
    height: 70px;
    font-size: 14px;
    .logo {
      width: 45px;
      height: 45px;
      border-radius: 3px;
      margin: 0 10px;
    }
  }
  .line_box_right {
    flex-wrap: wrap;
  }
}
</style>
