var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c("BaseInfo", {
            ref: "baseInfo",
            staticClass: "component",
            attrs: {
              disabled: _vm.type === "show",
              "trade-goods-dto": _vm.addDetail.tradeGoodsDto,
              "trade-shop-goods-relation-dtos":
                _vm.addDetail.tradeShopGoodsRelationDtos,
            },
          }),
          [1, 2, 5, 6, 7, 8, 9].includes(Number(_vm.goodsType))
            ? _c("BrandInfo", {
                attrs: {
                  disabled:
                    _vm.type === "show" ||
                    (_vm.type === "edit" && _vm.isOnSale == 1),
                  "show-authorization":
                    Number(_vm.goodsType) === 1 && !_vm.isAssembly,
                  "goods-detail-brand-info": _vm.addDetail.goodsDetailBrandInfo,
                  "backend-category-id": _vm.query.backendCategoryId,
                  "suppliers-id": _vm.query.suppliersId,
                },
                on: { change: (e) => (_vm.addDetail.goodsDetailBrandInfo = e) },
              })
            : _vm._e(),
          [1].includes(+_vm.goodsType) || _vm.isAssembly
            ? _c("GuaranteeInfo", {
                ref: "guaranteeRef",
                attrs: {
                  disabled:
                    _vm.type === "show" ||
                    (_vm.type === "edit" && _vm.isOnSale == 1),
                  "goods-base-guarantees": _vm.addDetail.goodsBaseGuarantees,
                },
              })
            : _vm._e(),
          _vm.goodsType == 1 && !_vm.isAssembly
            ? _c("Authentication", {
                ref: "authenticationRef",
                attrs: {
                  disabled: _vm.type === "show",
                  "goods-certificates": _vm.addDetail.goodsCertificates,
                },
              })
            : _vm._e(),
          ![5, 6, 8, 9].includes(+_vm.goodsType) && !_vm.isAssembly
            ? _c("AttrInfo", {
                ref: "attrInfo",
                staticClass: "component",
                attrs: {
                  disabled:
                    _vm.type === "show" ||
                    (_vm.type === "edit" && _vm.isOnSale == 1),
                  people: "gys",
                  "trade-attribute-dtos": _vm.addDetail.tradeAttributeDtos,
                  "trade-goods-attribute-relation-dtos":
                    _vm.addDetail.tradeGoodsAttributeRelationDtos,
                },
              })
            : _vm._e(),
          _vm.goodsType == "7"
            ? _c("BaseGoodsAttr", {
                ref: "baseGoodsAttr-imei",
                attrs: {
                  id: "imei",
                  title: "IMEI编号",
                  type: "string",
                  required: true,
                  disabled: _vm.type === "show",
                  "trade-goods-dto": _vm.addDetail.tradeGoodsDto,
                },
              })
            : _vm._e(),
          _vm.goodsType == "7"
            ? _c("DeliverGoodsNum", {
                attrs: {
                  disabled: _vm.type === "show",
                  shipments: _vm.addDetail.shipments,
                },
                on: {
                  "update:shipments": function ($event) {
                    return _vm.$set(_vm.addDetail, "shipments", $event)
                  },
                },
              })
            : _vm._e(),
          ![2, 9].includes(+_vm.goodsType)
            ? _c("SkuInfo", {
                ref: "skuInfo",
                staticClass: "component",
                attrs: {
                  disabled:
                    _vm.type === "show" ||
                    (_vm.type === "edit" && _vm.isOnSale == 1),
                  "trade-spec-dtos": _vm.addDetail.tradeSpecDtos,
                  "trade-goods-sku-dtos": _vm.addDetail.tradeGoodsSkuDtos,
                  "trade-service-tag-dtos": _vm.addDetail.tradeServiceTagDtos,
                  "project-code": _vm.projectCode,
                  supply: _vm.supply,
                  "is-assembly": _vm.isAssembly,
                  "is-not-sale": _vm.isNotSale,
                },
                on: {
                  "update:tradeServiceTagDtos": function ($event) {
                    return _vm.$set(
                      _vm.addDetail,
                      "tradeServiceTagDtos",
                      $event
                    )
                  },
                  "update:trade-service-tag-dtos": function ($event) {
                    return _vm.$set(
                      _vm.addDetail,
                      "tradeServiceTagDtos",
                      $event
                    )
                  },
                },
              })
            : _vm._e(),
          _c("DetailInfo", {
            ref: "detailInfo",
            staticClass: "component",
            attrs: {
              disabled: _vm.type === "show",
              "trade-goods-detail-dtos": _vm.addDetail.tradeGoodsDetailDtos,
            },
          }),
          [5, 6, 8, 9].includes(+_vm.goodsType)
            ? _c("BookRule", {
                staticClass: "component",
                attrs: {
                  disabled:
                    _vm.type === "show" ||
                    (_vm.type === "edit" && _vm.isOnSale == 1),
                  info: _vm.ployRule,
                },
                on: { getBookData: _vm.getBookData },
              })
            : _vm._e(),
          _vm.userInfo.isMemberStore
            ? _c("SupplierInfo", {
                ref: "supplierRef",
                attrs: {
                  people: "gys",
                  disabled:
                    _vm.type === "show" ||
                    (_vm.type === "edit" && _vm.isOnSale == 1),
                  "trade-goods-dto": _vm.addDetail.tradeGoodsDto,
                  supply: _vm.supply,
                },
              })
            : _vm._e(),
          [1, 7].includes(+_vm.goodsType)
            ? [
                _c("FreightInfo", {
                  ref: "freightInfo",
                  attrs: {
                    disabled:
                      _vm.type === "show" ||
                      (_vm.type === "edit" && _vm.isOnSale == 1),
                    people: "gys",
                    "trade-template-goods-relation-dtos":
                      _vm.addDetail.tradeTemplateGoodsRelationDtos,
                    "freight-time": _vm.freightTime,
                  },
                }),
              ]
            : _vm._e(),
          _c("SellTime", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: ![9].includes(+_vm.goodsType) && !_vm.isNotSale,
                expression: "![9].includes(+goodsType) && !isNotSale",
              },
            ],
            ref: "sellTimeRef",
            attrs: {
              disabled:
                _vm.type === "show" ||
                (_vm.type === "edit" && _vm.isOnSale == 1),
              "trade-goods-dto": _vm.addDetail.tradeGoodsDto,
            },
          }),
          _vm.userInfo.isMemberStore && _vm.goodsType == "1"
            ? _c("SaleTarget", {
                ref: "saleTargetRef",
                attrs: {
                  people: "gys",
                  disabled:
                    _vm.type === "show" ||
                    (_vm.type === "edit" && _vm.isOnSale == 1),
                  "trade-goods-dto": _vm.addDetail.tradeGoodsDto,
                },
              })
            : _vm._e(),
        ],
        2
      ),
      [2].includes(+_vm.goodsType)
        ? [
            _vm.type == "add"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.toSubmit("homeAdd")
                      },
                    },
                  },
                  [_vm._v(" 下一步（设置房源） ")]
                )
              : _vm._e(),
            _vm.type == "edit" && _vm.goodStatus == 0
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.toSubmit("homeEditSetPrice")
                      },
                    },
                  },
                  [_vm._v(" 下一步（设置房源） ")]
                )
              : _vm._e(),
            _vm.type == "edit" &&
            _vm.goodStatus == 0 &&
            _vm.addDetail.tradeGoodsSkuDtos &&
            _vm.addDetail.tradeGoodsSkuDtos.length > 0
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.toSubmit("homeEditAuto")
                      },
                    },
                  },
                  [_vm._v(" 提交审核 ")]
                )
              : _vm._e(),
            _vm.type == "edit" && _vm.goodStatus == 3
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.toSubmit("homeEditAuto")
                      },
                    },
                  },
                  [_vm._v(" 提交审核 ")]
                )
              : _vm._e(),
            _vm.type == "edit" && _vm.goodStatus == 2
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.toSubmit("homeEdit")
                      },
                    },
                  },
                  [_vm._v(" 保 存 ")]
                )
              : _vm._e(),
            _vm.type == "edit" && _vm.goodStatus == 2
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.toSubmit("homeEditPrice")
                      },
                    },
                  },
                  [_vm._v(" 保存并设置房源 ")]
                )
              : _vm._e(),
          ]
        : _vm._e(),
      ![2, 8, 9].includes(+_vm.goodsType) && !_vm.isAddSuppleGoods()
        ? [
            _vm.type == "add"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.toSubmit("add")
                      },
                    },
                  },
                  [_vm._v(" 保 存 ")]
                )
              : _vm._e(),
            _vm.type == "add"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.toSubmit("addAndAudit")
                      },
                    },
                  },
                  [_vm._v(" 创建并提交审核 ")]
                )
              : _vm._e(),
            _vm.type == "edit" && _vm.goodStatus != 2
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.toSubmit("edit")
                      },
                    },
                  },
                  [_vm._v(" 保 存 ")]
                )
              : _vm._e(),
            _vm.type == "edit" && _vm.goodStatus != 2
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.toSubmit("editAndAudit")
                      },
                    },
                  },
                  [_vm._v(" 提交审核 ")]
                )
              : _vm._e(),
            _vm.type == "edit" && _vm.goodStatus == 2
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.toSubmit("editAuditOpen")
                      },
                    },
                  },
                  [_vm._v(" 保 存 ")]
                )
              : _vm._e(),
            _vm.type == "show" && _vm.pageAudit == "1"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        _vm.showAudit = true
                      },
                    },
                  },
                  [_vm._v(" 审 核 ")]
                )
              : _vm._e(),
          ]
        : _vm._e(),
      [8].includes(+_vm.goodsType) && _vm.type !== "show"
        ? [
            +_vm.isOnSale === 1
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.toSubmit("edit")
                      },
                    },
                  },
                  [_vm._v(" 保 存 ")]
                )
              : _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.toNextPage },
                  },
                  [_vm._v(" 下一步(设置权益) ")]
                ),
          ]
        : _vm._e(),
      [9].includes(+_vm.goodsType) && _vm.type !== "show"
        ? [
            +_vm.goodStatus === 2
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.toSubmit("edit")
                      },
                    },
                  },
                  [_vm._v(" 保 存 ")]
                )
              : [
                  _vm.type === "add"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.toNextPage },
                        },
                        [_vm._v(" 下一步(设置规格) ")]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.toSubmit("editAndAudit")
                            },
                          },
                        },
                        [_vm._v(" 提交审核 ")]
                      ),
                ],
          ]
        : _vm._e(),
      _vm.isAddSuppleGoods()
        ? [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: {
                  click: function ($event) {
                    return _vm.toSubmit("addAndAudit")
                  },
                },
              },
              [_vm._v(" 提交审核 ")]
            ),
          ]
        : _vm._e(),
      _vm.isShowViewGoods()
        ? _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.onShowPreview },
            },
            [_vm._v(" 预 览 ")]
          )
        : _vm._e(),
      _c(
        "div",
        [
          _c("AuditModel", {
            attrs: {
              name: _vm.addDetail.tradeGoodsDto
                ? _vm.addDetail.tradeGoodsDto.name
                : "",
              "show-audit": _vm.showAudit,
            },
            on: { closeModel: _vm.closeModel },
          }),
        ],
        1
      ),
      _vm.showGoods
        ? _c("ShowGoods", {
            attrs: {
              id: _vm.query.goodsId,
              "show-goods": _vm.showGoods,
              "goods-type": _vm.query.goodsType,
            },
            on: {
              closeShowGoods: function ($event) {
                _vm.showGoods = false
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }