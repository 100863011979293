<template>
  <el-dialog v-if="showOff" :visible.sync="showOff" title="批量设置佣金" width="600px">
    <div class="title">选中商品都按照以下规则设置佣金</div>
    <div class="ratio-box">
      <span class="start">*</span>
      佣金 = 当前售价 * <el-input v-model="ratio" size="mini" placeholder="请输入比例" @input="inputRatio"></el-input> %
    </div>
    <div class="time-box">
      <span v-if="type === 'add'" class="start">*</span>
      <span class="label">分销时间</span> 
      <el-date-picker
        v-model="times"
        type="datetimerange"
        value-format="timestamp"
        size="mini"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
    </div>
    <div class="tip">
      注：当前售价为设置佣金时的商品售价，非实时商品售价。佣金完成设置后，修改商品售价佣金不同步更新。
    </div>
    <div class="btn-box">
      <el-button type="primary" size="mini" :loading="subLoading" @click="clickSubmit">确定</el-button>
      <el-button size="mini" @click="clickCancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {inNum, sleep} from '@/com.js';
import dayjs from 'dayjs';

export default {
  data() {
    return {
      showOff: false,
      type: '',
      list: [],
      ratio: '',
      times: [],
      subLoading: false
    };
  },
  methods: {
    show(list, type) {
      this.type = type;
      this.list = list;
      if (this.type === 'add') this.times = [dayjs().valueOf(), dayjs().add(1, 'year').valueOf()];
      this.showOff = true;
    },
    clickCancel() {
      this.showOff = false;
      this.type = '';
      this.list = [];
      this.ratio = '';
      this.times = [];
    },
    clickSubmit() {
      if (this.ratio === '') {
        this.$message({type: 'error', message: '请输入佣金比例'});
        return;
      }
      if (this.type === 'add' && (!this.times || !this.times.length)) {
        this.$message({type: 'error', message: '请选择分销时间'});
        return;
      }
      this.subLoading = true;
      sleep(this.submit);
    },
    async submit() {
      const [validStartTime, validEndTime] = this.times || [];
      const params = {
        goodsIds: this.list.map(item => item.id),
        ratio: this.ratio,
        validStartTime,
        validEndTime
      };
      const pathKey = this.type === 'add' ? 'batchAddDistribute' : 'batchEditDistribute';
      const {code, msg} = await this.$axios.post(this.$api.DistributeController[pathKey], params);
      this.subLoading = false;
      if (code !== 0) {
        this.$message({type: 'error', message: msg});
        return;
      }

      this.$message({type: 'success', message: '操作成功'});
      this.clickCancel();
      this.$emit('close');
    },
    inputRatio(val) {
      this.ratio = inNum(val, {zero: false});
      if (val > 100 || val === '100.') this.ratio = 100;
    }
  }
}
</script>

<style lang="scss" scoped>
.ratio-box{
  display: flex; align-items: center; margin: 20px 0;

  .el-input{
    width: 100px; margin: 0 5px;
  }
}
.time-box{
  margin-bottom: 20px;

  .label{
    margin-right: 10px;
  }
}
.tip{
  line-height: 24px; margin-bottom: 50px; color: red;
}
.btn-box{
  text-align: center;
}
.start{
  margin-right: 4px; color: red;
}
</style>