<template>
  <div>
    <h1 class="add_goods_title">
      设置运费<span>(以下3中配送方式需任选一种或多种)</span>
    </h1>
    <el-form ref="freightForm" label-width="110px" class="demo-ruleForm">
      <el-form-item label="普通快递：">
        <el-select v-if="people === 'gys'" v-model="ruleForm.commonFreight" :disabled="disabled" class="width360"
          placeholder="请选择快递模板" size="mini" show-word-limit value-key="id" clearable
          @visible-change="visibleChangeCommon">
          <el-option v-for="(item, i) in commonList" :key="i" :label="item.name" :value="item" />
        </el-select>
        <!-- <el-button size="mini" @click="addSelfFreight(1)" style="margin-left: 30px">
          查看详情
        </el-button> -->
        <el-button v-if="people === 'gys'" size="mini" style="margin-left: 30px" @click="addSelfFreight(1)">
          +新增普通快递
        </el-button>

        <div v-if="people === 'admin'">
          <el-link v-if="ruleForm.commonFreight && ruleForm.commonFreight.name" type="primary"
            @click="toFreightList(1)">
            {{ ruleForm.commonFreight.name }}
          </el-link>
          <span v-else>不支持</span>
        </div>
      </el-form-item>
      <el-form-item v-if="$route.query.goodsType != 7" label="同城配送：">
        <el-select v-if="people === 'gys'" v-model="ruleForm.cityFreight" class="width360" :disabled="disabled"
          placeholder="请选择快递模板" size="mini" show-word-limit value-key="id" clearable
          @visible-change="visibleChangeCity">
          <el-option v-for="(item, i) in cityList" :key="i" :label="item.name" :value="item" />
        </el-select>
        <el-button v-if="people === 'gys'" size="mini" style="margin-left: 30px" @click="addSelfFreight(2)">
          +新增同城配送
        </el-button>

        <div v-if="people === 'admin'">
          <el-link v-if="ruleForm.cityFreight && ruleForm.cityFreight.name" type="primary" @click="toFreightList(2)">
            {{ ruleForm.cityFreight.name }}
          </el-link>
          <span v-else>不支持</span>
        </div>
      </el-form-item>
      <el-form-item v-if="$route.query.goodsType != 7" label="自提：">
        <!-- <el-radio-group v-if="people === 'gys'" v-model="ruleForm.selfFreight">
          <el-radio :label="1">支持</el-radio>
          <el-radio :label="2">不支持</el-radio>
        </el-radio-group> -->
        <div v-if="people === 'gys'" class="tags-box">
          <div v-if="!ruleForm.ztds.length" style="height: 32px; line-height: 32px; margin-bottom: 10px;">未选择自提点</div>
          <el-tag v-for="tag in ruleForm.ztds" :key="tag.templateId" closable type="primary" :disable-transitions="true" @close="closeTag(tag)">{{tag.selfName}}</el-tag>
        </div>
        <div style="display: flex;">
          <el-button v-if="people === 'gys'" type="primary" size="mini" @click="selectztd">
            选择自提点
          </el-button>
          <el-button v-if="people === 'gys'" size="mini" @click="addSelfFreight(3)">
            +新增自提地址
          </el-button>
        </div>

        <div v-if="people === 'admin'">
          <span v-if="ruleForm.selfFreight && ruleForm.selfFreight.templateId == '0'" style="color: #1890ff">支持</span>
          <span v-else>不支持</span>
        </div>
      </el-form-item>
      <el-form-item label="提示：">
        <div>
          设置运费之前需要配置相应模式的运费模板，如果没有配置，点击进行新增。
        </div>
      </el-form-item>
    </el-form>

    <!-- 发货时间 -->
    <FreightTimeInfo v-if="ruleForm.commonFreight || ruleForm.cityFreight" :disabled="disabled"
      :freight-time="freightTime" />

    <!-- 选择自提点 -->
    <Zitidian ref="ZitidianRef" @check="checkAddress" />
  </div>
</template>

<script>
import FreightTimeInfo from './freightTimeInfo';
import Zitidian from './Zitidian';

export default {
  components: {
    FreightTimeInfo, Zitidian
  },
  props: ['disabled', 'tradeTemplateGoodsRelationDtos', 'people', 'freightTime'],
  data() {
    return {
      ruleForm: {
        commonFreight: null,
        cityFreight: null,
        selfFreight: 2,
        ztds: [] // 已选自提点
      },
      commonList: [],
      cityList: [],
      // selfList: [],
      pageType: '',
      editData: [],
    };
  },
  watch: {
    async tradeTemplateGoodsRelationDtos(data) {
      if (data) {
        if (this.people === 'gys') {
          await this.getCommonList();
          await this.getCityList();
          if (this.pageType != 'add') {
            this.setEdit();
          }
        } else {
          for (let item of data) {
            if (item.templateType == 1) {
              this.ruleForm.commonFreight = item;
            }
            if (item.templateType == 2) {
              this.ruleForm.cityFreight = item;
            }
            if (item.templateType == 3) {
              this.ruleForm.selfFreight = item;
            }
          }
        }
      }
    },
  },
  async mounted() {
    this.pageType = this.$route.query.type;
  },
  methods: {
    selectztd() {
      this.$refs.ZitidianRef.show(this.ruleForm.ztds);
    },
    checkAddress(list) {
      this.ruleForm.ztds = list || [];
    },
    closeTag(tag) {
      this.ruleForm.ztds = this.ruleForm.ztds.filter(item => item.templateId !== tag.templateId);
    },
    setEdit() {
      // console.log(this.commonList,999);
      this.tradeTemplateGoodsRelationDtos
        && this.tradeTemplateGoodsRelationDtos.forEach((item) => {
          console.log(this.commonList, 666);
          if (item.templateType == '1') {
            this.ruleForm.commonFreight = this.commonList.find(
              (find) => find.id == item.templateId,
            );
          } else if (item.templateType == '2') {
            this.ruleForm.cityFreight = this.cityList.find(
              (find) => find.id == item.templateId,
            );
          } else if (item.templateType == '3') {
            // if (item.templateId == '0') {
            //   this.ruleForm.selfFreight = 1;
            // } else {
            //   this.ruleForm.selfFreight = 2;
            // }
            item.templateId = +item.templateId;
            this.ruleForm.ztds.push(item);
          }
        });
      // console.log(this.commonList,999)
    },
    getForm(rank) {
      return new Promise((resolve) => {
        if (this.people === 'admin') {
          resolve(this.tradeTemplateGoodsRelationDtos);
          return;
        }
        // if (rank !== 'easy' && !this.ruleForm.commonFreight && !this.ruleForm.cityFreight && this.ruleForm.selfFreight != 1) {
        //   this.$message({ type: 'error', message: '请至少选择一种快递方式（设置运费）' });
        //   return;
        // }
        if (rank !== 'easy' && !this.ruleForm.commonFreight && !this.ruleForm.cityFreight && !this.ruleForm.ztds.length) {
          this.$message({ type: 'error', message: '请至少选择一种快递方式（设置运费）' });
          return;
        }
        let newArray = [];
        for (let key in this.ruleForm) {
          if (key === 'ztds') {
            // if (this.ruleForm.selfFreight === 1) {
            //   newArray.push({
            //     name: '自提',
            //     templateType: 3,
            //     templateId: 0,
            //     goodsId: this.$route.query.goodsId
            //       ? this.$route.query.goodsId
            //       : null,
            //   });
            // }

            this.ruleForm.ztds.forEach(item => {
              newArray.push({
                name: item.name,
                templateType: 3,
                templateId: item.templateId,
                goodsId: this.$route.query.goodsId
                  ? this.$route.query.goodsId
                  : null,
              });
            });
          } else if (key === 'commonFreight') {
            this.ruleForm[key] && newArray.push({
              ...this.ruleForm[key],
              templateType: 1,
              templateId: this.ruleForm[key].id,
              goodsId: this.$route.query.goodsId
                ? this.$route.query.goodsId
                : null,
            });
          } else if (key === 'cityFreight') {
            this.ruleForm[key] && newArray.push({
              ...this.ruleForm[key],
              templateType: 2,
              templateId: this.ruleForm[key].id,
              goodsId: this.$route.query.goodsId
                ? this.$route.query.goodsId
                : null,
            });
          }
        }
        resolve(newArray);
      });
    },
    getCommonList() {
      return new Promise((resolve) => {
        this.$axios
          .get(this.$api.trade_template_mail.list, {
            params: {
              templateType: 1,
            },
          })
          .then((res) => {
            if (res.code === 0 && res.data) {
              this.commonList = res.data.filter((item) => item.tradeBillCommonDtoList);
              resolve(true);
            } else {
              this.commonList = [];
            }
          });
      });
    },
    getCityList() {
      return new Promise((resolve) => {
        this.$axios
          .get(this.$api.trade_template_mail.list, {
            params: {
              templateType: 2,
            },
          })
          .then((res) => {
            if (res.code === 0) {
              let newArray = [];
              res.data && res.data.forEach((item) => {
                item.tradeBillCommonDtoList && newArray.push({
                  ...item.tradeBillCommonDtoList[0],
                  ...item,
                  billId: item.tradeBillCommonDtoList[0].id,
                });
              });
              this.cityList = newArray;
              resolve(true);
            } else {
              this.cityList = [];
            }
          });
      });
    },
    addSelfFreight(type) {
      let name = 'common';
      if (type === 1) {
        name = 'common';
      } else if (type === 2) {
        name = 'city';
      } else if (type === 3) {
        name = 'self';
      }
      window.open(`${window.location.origin}/nb/goods/freight/${name}?type=add&storeId=${this.$route.query.storeId}`);
    },
    // getSelfList() {
    //   return new Promise((resolve) => {
    //     this.$axios
    //       .get(this.$api.trade_template_mail.list, {
    //         params: {
    //           templateType: 3,
    //         },
    //       })
    //       .then((res) => {
    //         if (res.code === 0) {
    //           this.selfList = res.data;
    //           resolve();
    //         } else {
    //           this.selfList = [];
    //         }
    //       });
    //   });
    // },
    visibleChangeCommon(e) {
      if (e) {
        this.getCommonList();
      }
    },
    visibleChangeCity(e) {
      if (e) {
        this.getCityList();
      }
    },
    toFreightList(type) {
      // 运营后台
      let url = `${window.location.origin}/nb/goods/freight?freightType=${type}&storeId=${this.$route.query.storeId}`;
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.tags-box{
  line-height: 1;
  .el-tag{
    margin: 0 10px 10px 0;
  }
}
</style>
