var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showOff
    ? _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showOff,
            title: "批量设置佣金",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showOff = $event
            },
          },
        },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v("选中商品都按照以下规则设置佣金"),
          ]),
          _c(
            "div",
            { staticClass: "ratio-box" },
            [
              _c("span", { staticClass: "start" }, [_vm._v("*")]),
              _vm._v(" 佣金 = 当前售价 * "),
              _c("el-input", {
                attrs: { size: "mini", placeholder: "请输入比例" },
                on: { input: _vm.inputRatio },
                model: {
                  value: _vm.ratio,
                  callback: function ($$v) {
                    _vm.ratio = $$v
                  },
                  expression: "ratio",
                },
              }),
              _vm._v(" % "),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "time-box" },
            [
              _vm.type === "add"
                ? _c("span", { staticClass: "start" }, [_vm._v("*")])
                : _vm._e(),
              _c("span", { staticClass: "label" }, [_vm._v("分销时间")]),
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "value-format": "timestamp",
                  size: "mini",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.times,
                  callback: function ($$v) {
                    _vm.times = $$v
                  },
                  expression: "times",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "tip" }, [
            _vm._v(
              " 注：当前售价为设置佣金时的商品售价，非实时商品售价。佣金完成设置后，修改商品售价佣金不同步更新。 "
            ),
          ]),
          _c(
            "div",
            { staticClass: "btn-box" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    loading: _vm.subLoading,
                  },
                  on: { click: _vm.clickSubmit },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.clickCancel } },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }